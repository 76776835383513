import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { StaticQuery, graphql, withPrefix } from "gatsby";

const SEO = ({ title, description, image, pathname, article }) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          defaultTitle,
          // titleTemplate,
          defaultDescription,
          siteUrl,
          defaultImage
          // twitterUsername,
        }
      }
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname || "/"}`
      };
      return (
        <Helmet>
          <html lang="en" />
          <title>{seo.title}</title>
          <meta name="description" content={seo.description} />

          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={`${withPrefix("/")}img/everdays-logo-large.png`}
          />
          <link
            rel="icon"
            type="image/png"
            href={`${withPrefix("/")}img/everdays-logo-favicon.png`}
            sizes="32x32"
          />
          <link
            rel="icon"
            type="image/png"
            href={`${withPrefix("/")}img/everdays-logo-faviocn.png`}
            sizes="16x16"
          />

          <link
            rel="mask-icon"
            href={`${withPrefix("/")}img/everdays-logo-large.png`}
            color="#ff4400"
          />

          <meta name="theme-color" content="#0072cd" />

          <meta property="og:title" content={seo.title} />
          <meta property="og:url" content="/" />
          <meta property="og:image" content={seo.image} />
        </Helmet>
      );
    }}
  />
);

export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool
};
SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: url
      }
    }
  }
`;
