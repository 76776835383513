import React from "react";
import Helmet from "../components/Helmet";
import "./all.sass";

const TemplateWrapper = ({ children }) => {
  return (
    <div>
      <Helmet />
      <div>{children}</div>
    </div>
  );
};

export default TemplateWrapper;
